/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
