/* General Reset */
@font-face {
  font-family: 'Cubano';
  src: url('./font2.otf') format('opentype');
}

@font-face {
  font-family: 'GTEastiDisplay';
  src: url('./font1.otf') format('opentype');
}

@font-face {
  font-family: 'Hore';
  src: url('./font3.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cubano', sans-serif; /* Using Cubano for the main content */
}

body {
  background-color: #fff;
  color: #333;
  line-height: 1.6;
}

/* Container Layout */
.sodas-container {
  padding: 2rem;
  background-color: #FDF8F3; /* Cream background */
  text-align: center;
}

/* Title Styling */
.sodas-title {
  font-size: 3rem;
  font-family: 'GTEastiDisplay', sans-serif; /* Bold and readable */
  color: #FF851B; /* Orange title */
  font-weight: 700;
  margin-bottom: 1.5rem;
}

/* Soda Sizes Styling */
.soda-sizes {
  list-style: none;
  padding-left: 0;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.soda-sizes li {
  margin-bottom: 0.8rem;
}

.price {
  font-size: 1.2rem; /* Slightly larger prices for better visibility */
  color: #FF851B; /* Orange prices */
  font-weight: bold;
}

/* Signature Soda Flavors */
.flavor-list {
  list-style: none;
  padding-left: 0;
  font-size: 1.3rem;
  line-height: 1.8;
  margin-bottom: 3rem;
  text-align: justify;
  max-width: 800px;
  margin: 0 auto;
}

.flavor-list li {
  margin-bottom: 1.2rem;
}

.highlight {
  font-family: 'Hore', sans-serif;
  font-weight: bold;
  color: #FF851B; /* Orange highlight */
}

/* Contact Section */
.contact-section {
  margin-top: 3rem;
}

.contact-title {
  font-size: 2rem;
  font-family: 'GTEastiDisplay', sans-serif;
  color: #FF851B;
  margin-bottom: 1rem;
}

.contact-email {
  font-size: 1.1rem;
  font-family: 'Cubano', sans-serif;
  color: #555;
}

.email {
  color: #FF851B;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sodas-title {
    font-size: 2.5rem;
  }

  .soda-sizes li, .flavor-list li {
    font-size: 1.2rem;
  }

  .price {
    font-size: 1rem;
  }

  .contact-title {
    font-size: 1.8rem;
  }
}
