/* General Reset */
@font-face {
  font-family: 'Cubano';
  src: url('./font2.otf') format('opentype');
}

@font-face {
  font-family: 'GTEastiDisplay';
  src: url('./font1.otf') format('opentype');
}

@font-face {
  font-family: 'Hore';
  src: url('./font3.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cubano', sans-serif; /* Using custom font */
}

body {
  background-color: #fff;
  color: #333;
  line-height: 1.6;
}

/* Container Layout */
.treats-container {
  padding: 2rem;
  background-color: #FFFAF3; /* Cream background */
  text-align: center;
}

/* Title Styling */
.treats-title {
  font-size: 3rem;
  font-family: 'GTEastiDisplay', sans-serif; /* Bold, clear font */
  color: #FF851B; /* Orange title */
  font-weight: 700;
  margin-bottom: 1.5rem;
}

/* Menu Section */
.treats-menu {
  list-style: none;
  padding-left: 0;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.treats-menu li {
  margin-bottom: 0.8rem;
}

/* Price Styling with Easier-to-Read Font */
.price {
  font-size: 1.2rem; /* Larger price font */
  font-family: 'GTEastiDisplay', sans-serif; /* Easier to read font */
  color: #FF851B; /* Orange price color */
  font-weight: bold;
}

/* Contact Section */
.contact-section {
  margin-top: 3rem;
}

.contact-title {
  font-size: 2rem;
  font-family: 'GTEastiDisplay', sans-serif;
  color: #FF851B;
  margin-bottom: 1rem;
}

.contact-email {
  font-size: 1.1rem;
  font-family: 'Cubano', sans-serif;
  color: #555;
}

.email {
  color: #FF851B;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .treats-title, .contact-title {
    font-size: 2.5rem;
  }

  .treats-menu li {
    font-size: 1.2rem;
  }

  .price {
    font-size: 1rem;
  }

  .contact-title {
    font-size: 1.8rem;
  }
}
